import { useState, useEffect, useRef } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import InnerHTML from 'dangerously-set-html-content'

import { enableMarkdownTextPreview } from '@/lib/contentful/module'
import { usePageProps } from '../context/PagePropsContext'
import { viewContentPromotionEvent, selectContentPromotionEvent } from '@/lib/ga-events'
import { default as pagePaths } from '../../public/promotion-urls.json'
import useIsInViewport from '@/hooks/useIntersection'
import { useRouter } from 'next/router'
import { getContentModel } from '@/lib/helper'

const Markdown = (props) => {
  const { preview } = props
  const pageProps = usePageProps();
  const ref = useRef(null)
  const inViewport = useIsInViewport(ref)
  const router = useRouter()
  const textColor = props?.color
  const [item, setItem] = useState(preview ? props : props?.children || props?.fields?.markdownText)
  const [success, setSuccess] = useState(false)
  let pageModel = getContentModel('page')
  const withScript = (inputString) => {
    const pattern = /<script\b[^>]*>[\s\S]*?<\/script>/i

    return pattern.test(inputString)
  }

  useEffect(async () => {
    if (preview) {
      const res = await enableMarkdownTextPreview(props, preview)
      setItem(res)
    }
  }, [])

  useEffect(() => {
    if(inViewport) {
      if (success) return;
      const sucessFunction = async () => {
        try {
          // console.log(moduleProps)
          let currentPath = router.asPath;
          let promotionId = (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;
          promotionId = promotionId.startsWith('http') ? promotionId : 'https://'+promotionId

          //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
          let promotionName
          let creativeName
          let creativeSlot
          let locationId
          if(currentPath == '/') {
            promotionName = pageModel.name
          } else {
            let pageType = pagePaths.find(page => page.path == currentPath)
            promotionName = pageType?.type ? getContentModel(pageType?.type)?.name : pageModel.name
            // promotionName = promotionName+' :: Module | Hero Section'
          }
          let contentTypeName = getContentModel(pageProps?.sys?.contentType?.sys?.id)
          promotionName = promotionName+'::'+contentTypeName?.name
          creativeName = pageProps?.entryTitle || pageProps?.fields?.entryTitle || pageProps?.textAndImage || pageProps?.sectionName
          locationId = pageProps?.index ? pageProps?.index + 1 : 1
          creativeSlot = `Modules::${locationId}`
          let eventData = [
            {
              promotion_id: promotionId,
              promotion_name: promotionName,
              creative_name: creativeName,
              creative_slot: creativeSlot,
              location_id: locationId
            }
          ]
          let executed = false
          if(promotionId && promotionName && creativeName && creativeSlot && locationId) {
            executed = viewContentPromotionEvent(eventData)
          }
          if(executed) {
            setSuccess(true);
          }
        } catch (error) {
          console.error('View Promotion execution failed:', error);
        }
      };
  
      const intervalId = setInterval(async () => {
        if (!success) {
          await sucessFunction();
        } else {
          clearInterval(intervalId);
        }
      }, 500);
  
      return () => clearInterval(intervalId);
    }
  }, [inViewport, success, router.asPath])

  const selectPromotionEvent = (node) => {
    let linkText = node?.children[0]?.value
    let currentPath = router.asPath;
    let promotionId = (process.env.NEXT_PUBLIC_VERCEL_URL || process.env.NEXT_PUBLIC_BASE_URL)+currentPath;
    promotionId = promotionId.startsWith('http') ? promotionId : 'https://'+promotionId

    //PROMOTION NAME & CREATIVE NAME & CREATIVE SLOT & locationId
    let promotionName
    let creativeName
    let locationId
    if(currentPath == '/') {
      promotionName = pageModel.name
    } else {
      let pageType = pagePaths.find(page => page.path == currentPath)
      promotionName = pageType?.type ? getContentModel(pageType?.type)?.name : pageModel.name
    }
    let contentTypeName = getContentModel(pageProps?.sys?.contentType?.sys?.id)
    promotionName = promotionName+'::'+contentTypeName?.name
    creativeName = pageProps?.entryTitle || pageProps?.fields?.entryTitle || pageProps?.textAndImage || pageProps?.sectionName
    locationId = pageProps?.index ? pageProps?.index + 1 : 1
    let creativeSlot = 'Body Text::'+linkText
    if(promotionId && promotionName && creativeName && creativeSlot && locationId) {
      let eventData = [
        {
          promotion_id: promotionId,
          promotion_name: promotionName,
          creative_name: creativeName,
          creative_slot: creativeSlot,
          location_id: locationId
        }
      ]
      selectContentPromotionEvent(eventData)
    }
  }

  const components = {
    p: ({node, ...props}) => (<p {...props}  style={{color: textColor || 'black'}} >
            {props.children}
    </p>),
    strong: ({node, ...props}) => (<strong {...props} style={{color: textColor || 'black'}} >
            {props.children}
    </strong>),
    a: ({ node, ...props }) => (
        <a {...props} onClick={() => selectPromotionEvent(node)}>
            {props.children}
        </a>
    ),
    button: ({ node, ...props }) => (
        <button {...props} onClick={() => selectPromotionEvent(node)}>
            {props.children}
        </button>
    ),
  };

  return (
    <div className="module-markdown container prose" ref={ref}>
      {withScript(item) ? (
        <InnerHTML html={item} />
      ) : (
        <ReactMarkdown components={components} rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
          {item}
        </ReactMarkdown>
      )}
      <style jsx>
        {`
          .prose > * {
            text-align: inherit;
          }

          .list li::marker {
            color: #111111;
          }

          .prose a {
            display: unset;
          }
      `}
      </style>
    </div>
  )
}

export default Markdown
